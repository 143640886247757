.tip {
  width: 0px;
  height: 0px;
  position: absolute;
  background: transparent;
  border: 10px solid black;
}

.tip-up {
  top: -25px;
  /* Same as body margin top + border */
  left: 250px;
  border-right-color: transparent;
  border-left-color: transparent;
  border-top-color: transparent;
}

.tip-down {
  /* bottom: -25px; */
  left: 70px;
  border-right-color: transparent;
  border-left-color: transparent;
  border-bottom-color: transparent;
}

.nav-link {
  display: block;
  padding: .5rem 1.5rem;
}

button:active {
  background-color: black;
}

.tab,
.price-section {
  width: 100%;
}

.price-section {
  padding-bottom: 5px;
}

#commercial {
  float: right;
}

.input-range__label-container {
  /* visibility: hidden; */
}

.min-max {
  text-align: right;
  font-size: 11px;
}

.min-max span {
  color: red;
}

.price {
  font-size: 17px;
}

.price,
.min-max {
  color: black;
  position: relative;
  border: none;
  outline: none;
  /* width: 50%; */
  cursor: pointer;
  /* padding: 10px 0px; */
  transition: 0.3s;
  font-weight: 600;
}


.tab-active {
  background-color: #424242 !important;
  color: #F5F5F5;
}

.tab button::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 8px;
  border-style: solid;
  border-color: black transparent transparent transparent;
  visibility: hidden;
}

.input-range__track--background {
  left: 0;
  margin-top: -0.15rem;
  position: relative;
  right: 0;
  top: 50%;
}

.range {
  padding-bottom: 50px;
}

.tab button:focus::after {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s
}

/* Style the buttons inside the tab */
.tab button {
  text-align: center;
  position: relative;
  border: none;
  outline: none;
  width: 48%;
  cursor: pointer;
  padding: 5px 0px;
  transition: 0.3s;
  font-size: 80%;
  font-weight: 600;
  border-radius: 3px;
  background-color: #F5F5F5;
}

/* .form-control:after {
  content: '\f078';
  font: normal normal normal 17px/1 FontAwesome;
  color: #0ebeff;
  right: 11px;
  top: 6px;
  height: 34px;
  padding: 15px 0px 0px 8px;
  border-left: 1px solid #0ebeff;
  position: absolute;
  pointer-events: none;
} */

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: black;
  color: white;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

input#search {
  color: white;
}

.form-control {
  border-radius: 0.15rem;
  font-size: 13px;
  color: #424242;
  border: 1px solid #424242;
}

/* Create an active/current tablink class */
.tab button:focus {
  background-color: black;
  color: white;
}

.body .message {
  min-height: 30px;
  border-radius: 3px;
  font-family: Arial;
  font-size: 14px;
  line-height: 1.5;
  /* color: #797979; */
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

output {
  position: relative;
  width: 30px;
  height: 15px;
  background-color: #B00000;
  text-align: center;
  color: white;
  font-weight: 200;
  border-radius: 2px;
  display: inline-block;
  font-size: 10px;
  /* font: bold 15px/30px Georgia; */
  bottom: 175%;
  left: 0;
  top: 15px;
  margin-left: 5%;
}

.input-range__slider {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #B00000;
  border: 1px solid #B00000;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 0.8rem;
  margin-left: -0.1rem;
  margin-top: -1.1rem;
  outline: none;
  position: absolute;
  top: 40%;
  transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
  width: 0.8rem;
}

.input-range__track::before {
  left: 0;
  margin-top: -0.15rem;
  position: relative;
  right: 0;
  top: 50%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #B00000;
  border: 1px solid #B00000;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 0.5rem;
  margin-left: -0.1rem;
  margin-top: -1.1rem;
  outline: none;
  position: absolute;
  top: 50%;
  transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
  width: 0.5rem;
}

output::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 10px solid #B00000;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  /* top: 100%; */
  left: 50%;
  margin-left: -5px;
  margin-top: -10px;

}

.input-range__label--value {
  position: relative;
  width: auto;
  height: 14px;
  background-color: #B00000;
  text-align: center;
  color: white;
  font-weight: 200;
  border-radius: 2px;
  display: inline-block;
  font-size: 10px;
  /* font: bold 15px/30px Georgia; */
  bottom: 175%;
  left: 0;
  top: 7px;
  margin-left: -40%;
}

.input-range__label--value::after {
  display: none;
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 10px solid #B00000;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  /* top: 100%; */
  left: 50%;
  margin-left: -5px;
  margin-top: -10px;

}

.input-range__label--value::before {
  /* content: "text"; */
  left: -30px;
}

.input-range__label-container {
  left: 0%;
  padding: 0px 5px 0 5px;
  display: none;
}

.input-range__slider-container {
  visibility: visible;
  /* transition: left 0.3s ease-out; */
}

.input-range__track--active {
  background: #B00000
}

.react-autosuggest__suggestions-container {
  position: absolute;
  background: #fff;
  width: 90%;
}

.react-autosuggest__suggestions-container ul {
  list-style-type: none;
  cursor: pointer;
  max-height: 150px;
  overflow-y: scroll;

}